@use './variables' as vakiVariables;

$spacers: (
  0: 0,
  1: vakiVariables.$baseline * 1,
  2: vakiVariables.$baseline * 2,
  3: vakiVariables.$baseline * 3,
  4: vakiVariables.$baseline * 4,
  5: vakiVariables.$baseline * 5,
  6: vakiVariables.$baseline * 6,
  7: vakiVariables.$baseline * 7,
  8: vakiVariables.$baseline * 8,
  9: vakiVariables.$baseline * 9,
  10: vakiVariables.$baseline * 10,
  11: vakiVariables.$baseline * 11,
  12: vakiVariables.$baseline * 12,
  14: vakiVariables.$baseline * 14,
  15: vakiVariables.$baseline * 15,
  16: vakiVariables.$baseline * 16,
  17: vakiVariables.$baseline * 17,
  18: vakiVariables.$baseline * 18,
  19: vakiVariables.$baseline * 19,
  20: vakiVariables.$baseline * 20,
  21: vakiVariables.$baseline * 21,
  22: vakiVariables.$baseline * 22,
  23: vakiVariables.$baseline * 23,
  24: vakiVariables.$baseline * 24,
  25: vakiVariables.$baseline * 25,
  auto: auto
);

$percentage: (
  0: 0,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  75: 75%,
  100: 100%
);

$pixels: (
  230: 230px
);

$utilities: (
  'width': (
    property: width,
    class: w,
    values: $percentage
  ),
  'width-pixels': (
    property: width,
    class: wpx,
    values: $pixels
  ),
  'height': (
    property: height,
    class: h,
    values: $percentage
  ),
  'padding': (
    property: padding,
    class: p,
    values: $spacers
  ),
  'padding-x': (
    property: padding-right padding-left,
    class: px,
    values: $spacers
  ),
  'padding-y': (
    property: padding-top padding-bottom,
    class: py,
    values: $spacers
  ),
  'padding-bottom': (
    property: padding-bottom,
    class: pb,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'padding-top': (
    property: padding-top,
    class: pt,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin': (
    property: margin,
    class: m,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin-x': (
    property: margin-right margin-left,
    class: mx,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin-y': (
    property: margin-top margin-bottom,
    class: my,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin-top': (
    property: margin-top,
    class: mt,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin-right': (
    property: margin-right,
    class: mr,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin-bottom': (
    property: margin-bottom,
    class: mb,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  ),
  'margin-left': (
    property: margin-left,
    class: ml,
    values:
      map-merge(
        $spacers,
        (
          auto: auto
        )
      )
  )
);

@each $key, $utility in $utilities {
  $values: map-get($utility, values);

  @each $key, $value in $values {
    /* stylelint-disable */
    $properties: map-get($utility, property);

    @if type-of($properties) == 'string' {
      $properties: append((), $properties);
    }
    $property-class: if(
      map-has-key($utility, class),
      map-get($utility, class),
      nth($properties, 1)
    );
    $property-class: if($property-class == null, '', $property-class);
    $property-class-modifier: '-' + $key;
    /* stylelint-enable */
    .#{$property-class + $property-class-modifier} {
      @each $property in $properties {
        // stylelint-disable-next-line declaration-no-important
        #{$property}: $value !important;
      }
    }
    .-#{$property-class + $property-class-modifier} {
      @each $property in $properties {
        // stylelint-disable-next-line declaration-no-important
        #{$property}: -$value !important;
      }
    }
  }
}
