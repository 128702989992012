.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container {
  display: flex;
}

.slide-item {
  min-width: 360px;
}

.slide-gift-item {
  min-width: 100%;
}
