@use 'breakpoints' as breakpoints;
@use 'sass:math';

$height: 40px;
$total-height: 320px;
$percentage: math.percentage(math.div($height, $total-height - $height));

$social: (
  0: twitter,
  1: facebook,
  2: instagram,
  3: linkedin,
  4: whatsapp,
  5: youtube,
  6: code,
  7: url
);

@each $key, $value in $social {
  .social-icon.social-icon--#{$value} {
    background-position-y: $percentage * $key;
  }
}

.social-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: white;
  background-image: url('./../../assets/images/logos/social_media-icons.png');
  background-position-x: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  color: transparent;
}

.transparent {
  background-color: transparent !important;
}
