@use './../utils/colors' as colors;
.vaki-snackbar {
  background: colors.$BACKGROUND_DARK;
  font-weight: bold;
  .mat-simple-snackbar-action {
    color: colors.$PRIMARY !important;
  }

  &--error {
    background: colors.$WARN;
  }
}
