@use './../utils/colors' as colors;
@import './error-photo';
@import './loading';

.vaki-card {
  &__photo {
    display: flex;
    height: 180px;
  }

  &__photo--error {
    @include icon-error();
    font-size: 6em;
    height: 180px !important;
    width: 120% !important;
    text-align: center;
  }

  &__title {
    white-space: normal;
    text-overflow: ellipsis;
  }

  &__description {
    word-break: break-word;
    font-weight: 100;
    line-height: 1.2em;
    height: 100px;
    overflow-y: scroll;
  }

  &__loading {
    .mat-card-title,
    .vaki-card__description {
      border-radius: 5px;
      @include animate-loading();
      min-width: 120px;
      height: 1.3rem;
    }
    .vaki-card__photo--error {
      @include animate-loading();
    }
    * {
      color: colors.$BACKGROUND_LIGHT;
    }
  }

  &__closed {
    > *:not(.vaki-card__actions) {
      opacity: 0.7;
    }
  }

  .mat-progress-bar {
    height: 8px;
    margin-bottom: 4px;
    border-radius: 16px;
  }
}

.category-tag {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  z-index: 10;
  background: rgb(colors.$BACKGROUND_DARK, 0.7);
  border-radius: 16px;
  right: 12px;
  top: 120px;
  align-items: center;
}

.brand-logo {
  height: 24px;
  width: auto;
}
