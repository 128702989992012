@use './../utils/colors' as colors;

body {
  // Visibility
  .d-none {
    display: none;
  }

  .d-block {
    display: block;
  }

  .d-iblock {
    display: inline-block;
  }

  a.underline-none {
    text-decoration: none;
  }

  hr,
  .hr {
    border: 0;
    border-bottom: 1px solid colors.$DISABLED;
  }

  .radius-0 {
    border-radius: 0;
  }

  .overflow-y-scroll {
    overflow-y: scroll;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .overflow-auto {
    overflow: auto;
  }

  .overflow-hidden {
    overflow: hidden;
  }
}
