$states: (
  1: #8cc640,
  2: #eb5757,
  3: #f6a609,
  4: #f6a609,
  5: #f6a609
);

@each $key, $value in $states {
  .payment-state.payment-state--#{$key} {
    background-color: $value;
  }
}

.payment-state {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
}
