@use 'colors' as colors;

$iconSizes: 12, 14, 16, 18, 24, 36, 48;

@each $size in $iconSizes {
  mat-icon.icon-#{$size} {
    font-size: #{$size}px;
    width: #{$size}px;
    height: #{$size}px;
  }
}

// vakicon = VakIcons
[class*='vakicon'] {
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;
  justify-content: center;
}

.vakicon {
  &-lola {
    background-image: url('./../../assets/images/misc/lola-logo.svg');
    height: 45px;
    min-width: 44px;
  }

  &-google-btn {
    background-image: url('./../../assets/images/misc/google-logo.svg');
    height: 1em;
    width: 1em;
  }

  &-facebook-btn {
    background-image: url('./../../assets/images/icons/facebook-icon.ico');
    height: 1em;
    width: 1em;
  }

  &-twitter-btn {
    background-image: url('./../../assets/images/icons/twitter-icon.png');
    height: 1em;
    width: 1em;
  }
  &-apple-btn {
    background-image: url('./../../assets/images/icons/apple-icon.png');
    height: 1em;
    width: 1em;
  }
  &-mail-btn {
    background-image: url('./../../assets/images/icons/mail-icon.png');
    height: 1em;
    width: 1em;
    padding-top: 2px;
  }
}

.icon-check {
  transform: scale(2);
  margin: 10px;

  &--accepted {
    color: colors.$PRIMARY;
  }

  &--pending {
    color: colors.$DISABLED;
  }
}
