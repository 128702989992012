@use 'breakpoints' as breakpoints;
@use 'variables' as vakiVariables;

$image-height: 100px;

$padding-x4: (vakiVariables.$baseline * 4); // 16px
$padding-x6: (vakiVariables.$baseline * 6); // 24px
$padding-x8: (vakiVariables.$baseline * 8); // 32px

%container-base {
  margin: 0 auto;
  position: relative;
  width: vakiVariables.$container-xs;

  @include breakpoints.layout-bp(gt-sm) {
    max-width: 490px;
  }
}

.container {
  @extend %container-base;

  @media screen and (min-width: vakiVariables.$breakpoint-md) {
    max-width: 728px;
  }

  @media screen and (min-width: vakiVariables.$breakpoint-lg) {
    max-width: 860px;
  }

  @media screen and (min-width: vakiVariables.$breakpoint-xl) {
    max-width: vakiVariables.$container-xl;
  }
}

.container-sm {
  @include breakpoints.layout-bp(gt-sm) {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(breakpoints.$overlapping-gt, gt-sm);
  }
}

.container-xs {
  @include breakpoints.layout-bp(gt-xs) {
    margin-left: auto;
    margin-right: auto;
    max-width: map-get(breakpoints.$overlapping-gt, gt-xs);
  }
}

.grid-container {
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: repeat(1, 100%);

  @include breakpoints.layout-bp(md) {
    column-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoints.layout-bp(lg) {
    column-gap: 8px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include breakpoints.layout-bp(xl) {
    column-gap: 8px;
    grid-template-columns: repeat(3, minmax(0, 4fr));
  }
  &.no-gap {
    grid-row-gap: 0 !important;
  }
}
.grid-container-two-column {
  display: grid;
  margin-bottom: 32px;
  column-gap: 8px;
  row-gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  &.xs {
    @include breakpoints.layout-bp(xs) {
      grid-row-gap: 16px;
      grid-template-columns: repeat(1, 100%);
    }
  }

  &.md {
    @include breakpoints.layout-bp(lt-md) {
      grid-row-gap: 16px;
      grid-template-columns: repeat(1, 100%);
    }
  }
}

.grid-container-four-column {
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: repeat(1, 100%);

  @include breakpoints.layout-bp(md) {
    column-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoints.layout-bp(lg) {
    column-gap: 8px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include breakpoints.layout-bp(xl) {
    column-gap: 8px;
    grid-template-columns: repeat(4, minmax(0, 4fr));
  }
  &.no-gap {
    grid-row-gap: 0 !important;
  }
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.xs {
    @include breakpoints.layout-bp(xs) {
      flex-direction: column;
      align-items: center;
    }
  }
  &.md {
    @include breakpoints.layout-bp(lt-md) {
      flex-direction: column;
      align-items: center;
    }
  }
  &.lg {
    @include breakpoints.layout-bp(lt-lg) {
      flex-direction: column;
      align-items: center;
    }
  }

  &.left {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.flex-start {
  align-items: flex-start !important;
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &.left {
    align-items: flex-start !important;
  }
  &__block {
    display: block !important;
  }
  &.gap16 {
    gap: 16px;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
