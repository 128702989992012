@use '../utils/colors' as colors;

.vaki-main-picture {
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: 100%;

    &:nth-child(1) {
      object-fit: contain;
      z-index: 1;
    }
    &:nth-child(2) {
      object-fit: fill;
      filter: blur(24px);
      background-color: colors.$DARK_LIGHTER;
    }
  }
}
