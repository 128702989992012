// Custom margins
.mat-headline-4 {
  margin: 0 0 16px;
}
.mat-subheading-1,
.mat-subheading-2 {
  margin: 0 0 4px;
}

// Text alignments

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-end {
  text-align: end;
}

.align-center {
  display: inline-flex;
  align-items: center;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-capitalize {
  text-transform: capitalize;
}

.font-600 {
  font-weight: 600;
}

// Text colors
