@use './../utils/colors' as colors;
@use './../utils/variables' as vakiVariables;

$image-height: 200px;
// Edit Vaki card styles

mat-card.edit-card {
  border-radius: 0;
  border: 1px solid colors.$BACKGROUND_LIGHT;
  box-shadow: none;
  margin-bottom: 24px;
  padding: 16px 24px;

  @media screen and (min-width: vakiVariables.$breakpoint-md) {
    padding: 24px 40px;
  }

  @media screen and (min-width: vakiVariables.$breakpoint-lg) {
    padding: 48px 56px;
  }

  .summary-status {
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}

.contribute {
  @extend %border-primary;
  border-radius: 8px;
  border: 1px solid;
  box-shadow: 0 4px 8px -8px rgba(0, 0, 0, 0.8);
  padding: 8px;
  position: relative;

  &--unavailable {
    pointer-events: none;
    opacity: 0.7;
    border: 1px solid colors.$WARN;

    a {
      color: colors.$DISABLED;
    }
  }

  &--reward {
    display: grid;
    gap: vakiVariables.$baseline * 2;
    grid-template-areas:
      'reward_title reward_img'
      'reward_desc reward_desc'
      'reward_value reward_value';
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: vakiVariables.$breakpoint-xs) {
    padding: 12px;
  }

  @media screen and (min-width: vakiVariables.$breakpoint-md) {
    padding: 16px;
  }

  &__form {
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: colors.$DARK_LIGHTER;
    font-size: 17.4px;
    grid-area: reward_title;
    letter-spacing: 0.15px;
    margin: 0;
    max-height: $image-height;
    overflow-y: auto;
    cursor: pointer;
  }

  &__status {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__img {
    grid-area: reward_img;
    height: $image-height;
    max-height: $image-height;
    max-width: 100%;
    object-fit: contain;
    width: auto;
    cursor: pointer;
  }

  &__desc {
    border-top: 1px solid colors.$DISABLED;
    padding: 8px 0px;
    font-size: 14px;
    grid-area: reward_desc;
    line-height: 1.4;
    margin: 0;
    height: 125px;
    overflow-y: auto;
  }

  &__value {
    font-size: 20px;
    grid-area: reward_value;
  }
}

.button-card:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 20px colors.$PRIMARY;
  z-index: 1;
}
