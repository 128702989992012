@use './../utils/colors' as colors;

$base-color: colors.$DISABLED;
$animation-duration: 1000ms;
$gradient-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0,
    colors.$BACKGROUND_LIGHT,
    40px,
    $base-color 80px
  );
  background-size: 80vw;
}

@keyframes loading-lines {
  0% {
    background-position: -100px;
  }

  40% {
    background-position: 40vw;
  }

  100% {
    background-position: 60vw;
  }
}

@mixin animate-loading {
  @include background-gradient();
  animation: loading-lines $animation-duration infinite linear;
}
