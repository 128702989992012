// Baseline Size
$baseline: 4px;

// Breakpoints Sizes
$breakpoint-xs: 480px;
$breakpoint-md: 840px;
$breakpoint-lg: 960px;
$breakpoint-xl: 1280px;

// Containers from Media Queries
$container-xs: calc(100% - 24px);
$container-xl: 1140px;
