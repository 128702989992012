a[disabled] {
  pointer-events: none;
}

button,
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-stroked-button {
  text-transform: uppercase !important;
}
