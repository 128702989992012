.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.content-vertical-center {
  display: flex;
  align-items: center;
}
