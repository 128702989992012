@use './../utils/colors' as colors;

.summary-status {
  background-color: white;
  border-radius: 1px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
  color: colors.$BACKGROUND_DARK;
  padding: 16px;
  position: relative;

  &__progress {
    left: 0;
    position: absolute;
    top: -4px;
    width: 100%;
  }
}
