@use 'sass:math';

$height: 30px;
$total-height: 720px;
$percentage: math.percentage(math.div($height, $total-height - $height));

$flags: (
  1: ar,
  2: bo,
  3: cl,
  4: co,
  5: cr,
  6: ec,
  7: es,
  8: us,
  9: mx,
  10: py,
  11: pe,
  12: uy,
  13: ve,
  14: br,
  15: do,
  16: ni,
  17: hn,
  18: gt,
  19: sv,
  20: pa,
  21: au,
  22: gb,
  23: ca
);

@each $key, $value in $flags {
  .country-flag.country-flag--#{$value} {
    background-position-y: $percentage * $key;
  }
}

.country-flag {
  background: url('./../../assets/images/icons/flags.png') no-repeat;
  background-size: 100%;
  background-position-x: 0;
  height: 15px;
  overflow: hidden;
  width: 22.5px;
  min-width: 22.5px;
  display: inline-block;
}
