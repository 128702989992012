@use './themes/vaki/typography' as vakiTypography;
@use './utils/variables' as vakiVariables;
@use './utils/colors' as colors;
@use './utils/breakpoints' as breakpoints;

// Themes
@import './themes/vaki/vaki-light';

// Components
@import './components/buttons';
@import './components/cards';
@import './components/checkbox';
@import './components/containers';
@import './components/custom-swiper';
@import './components/loading';
@import './components/main-picture';
@import './components/payment-states';
@import './components/snackbar';
@import './components/mat-menu';
@import './components/summary-status';
@import './components/vaki-card';
@import './components/tabs';
@import './components/bottom-sheet';

// Utils
@import './utils/flags';
@import './utils/helpers';
@import './utils/icons';
@import './utils/position';
@import './utils/social-icons';
@import './utils/spacing';
@import './utils/texts';

html,
body {
  height: 100%;
  margin: 0;
}

@mixin respond-to($breakpoint) {
  $max: map-get(map-get(breakpoints.$breakpoints, $breakpoint), end);
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin hide-on-breakpoint($breakpoint) {
  $max: map-get(map-get(breakpoints.$breakpoints, $breakpoint), end);
  @media screen and (max-width: $max) {
    display: none !important;
  }
}

@mixin show-on-breakpoint($breakpoint) {
  $max: map-get(map-get(breakpoints.$breakpoints, $breakpoint), end);
  display: none !important;
  @media screen and (max-width: $max) {
    display: flex !important;
  }
}

// Generate classes dynamically for all breakpoints
@each $key, $value in breakpoints.$breakpoints {
  .hide-on--#{$key} {
    @include hide-on-breakpoint($key);
  }
}
@each $key, $value in breakpoints.$breakpoints {
  .show-on--#{$key} {
    @include show-on-breakpoint($key);
  }
}

.fixed-top {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  padding: 50px 0;
}

.remove-margin {
  margin: 0 !important;
}

.form-field-desktop {
  width: 50%;
}

.form-field-mobile {
  width: 100%;
  margin-left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}
.text-decoration-none {
  text-decoration: none;
}

.vaki-version {
  bottom: 16px;
  color: black;
  position: fixed;
  right: 16px;
  text-shadow: 0 0 1px white;
  z-index: 99999;
}

.lola-gif {
  width: 50px;
  height: auto;
}

.user-avatar {
  border-radius: 20px;
  height: 40px;
  width: 40px;
  object-fit: cover;
  border: 2px solid;
  border-color: colors.$PRIMARY;
  box-shadow: 0 0 3px 0 colors.$PRIMARY;
}

.user-avatar--large {
  border-radius: 60px;
  height: 120px;
  width: 120px;
  object-fit: cover;
  padding: 1px;
  border: 2px solid rgb(colors.$PRIMARY, 0.1);
}

.user-avatar--big {
  border-radius: 34px;
  height: 68px;
  width: 68px;
  object-fit: cover;
  border: 2px solid colors.$PRIMARY;
  box-shadow: 0 0 3px 0 colors.$PRIMARY;
}

// Iframe added by mercadopago script
#thm_mp_cntnr {
  position: absolute;
}

.overflow-text {
  overflow: hidden;
}

.mat-title-dialog-custom {
  font-size: 28px;
  line-height: 36px;
  @media screen and (min-width: vakiVariables.$breakpoint-md) {
    font-size: revert;
    line-height: revert;
  }
}
.fit-content {
  width: fit-content;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}
.w-40 {
  width: 40%;
}
.w-75 {
  width: 75%;
}
.w-25 {
  width: 25%;
}

.w-50-100 {
  width: 50%;
  &.right {
    margin-left: 8px;
  }
  &.xs {
    @include breakpoints.layout-bp(xs) {
      width: 100%;
      &.right {
        margin-left: 0;
      }
    }
  }
  &.md {
    @include breakpoints.layout-bp(lt-md) {
      width: 100%;
      &.right {
        margin-left: 0;
      }
    }
  }
}
.w-50-25 {
  width: 50%;
  &.xs {
    @include respond-to(xs) {
      width: 25%;
    }
  }

  &.md {
    @include respond-to(md) {
      width: 25%;
    }
  }
}
.w-45-100 {
  width: 45%;
  &.xs {
    @include respond-to(xs) {
      width: 100%;
    }
  }

  &.md {
    @include respond-to(md) {
      width: 100%;
    }
  }
}

.h-100 {
  height: 100%;
}

.modal-responsive {
  @media screen and (max-width: vakiVariables.$breakpoint-md) {
    max-height: 500px;
  }
}

.cover {
  object-fit: cover;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-x-hidden {
  overflow-x: hidden;
  overflow-y: hidden;
}
.text-bold {
  font-weight: bold;
}
.text-justify {
  text-align: justify;
}
.no-resize {
  resize: none !important;
}
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

footer {
  margin-top: auto;
}

.logo-partner {
  max-width: 125px;
  height: auto;
}
a {
  color: colors.$PRIMARY;
}

.brand-logo-24 {
  height: 24px;
  width: auto;
}
.brand-logo-32 {
  height: 32px;
  width: auto;
}

.timepicker-overlay {
  z-index: 2500 !important;
}
